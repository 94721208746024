import get from "lodash.get";
import set from "lodash.set";
import {
  getLink,
  isFeatureHiddenOnMobile
} from "~/shared-components/story-card/_utilities/helpers";
import { removeWWW } from "../utilities/www-remover";

/*
 * Reconcile the feature and chain customField values of either suppress-
 * or discountDuplicates. If feature-level doesn't exist or is "inherit"
 * use chain-level. If feature-level is "yes", the return true, otherwise false
 *
 * @param {str} key - one of "suppressDuplicates" or "discountDuplicates"
 * @param {obj} customFields - customFields of the feature
 * @param {obj} chainCustomFields - customFields of the chain
 *
 * @return {boolean} - whether duplicates should be discounted/suppressed or not
 */
const reconcileDuplicateProp = (key, customFields, chainCustomFields) =>
  !customFields?.[key] || customFields?.[key] === "inherit"
    ? !!chainCustomFields?.[key]
    : !!/^yes$/.test(customFields?.[key]);

export const reconcileSuppressDuplicates = (
  customFields,
  chainCustomFields,
  rootCustomFields
) => {
  if (!rootCustomFields?.enableDeduping) return false;
  return reconcileDuplicateProp(
    "suppressDuplicates",
    customFields,
    chainCustomFields
  );
};

export const reconcileDiscountDeduplicates = (
  customFields,
  chainCustomFields
) =>
  reconcileDuplicateProp("discountDuplicates", customFields, chainCustomFields);

export const getRenderedContentId = ({ content, overrides }) =>
  removeWWW(getLink({ content, overrides })?.link?.url);

export const isDifferentListOfRenderedContent = (list1, list2) =>
  list1?.length !== list2?.length || !list1?.every((_, i) => _ === list2[i]);

export const updateRenderedContentKey = ({
  renderedContent,
  key,
  listProperties,
  singletonProperties
}) => {
  renderedContent[key] = renderedContent[key] || {};
  Object.entries(listProperties).forEach(([listKey, listValue]) => {
    if (listValue && !renderedContent[key][listKey]?.includes(listValue)) {
      renderedContent[key][listKey] = [
        ...(renderedContent[key][listKey] || []),
        listValue
      ];
    }
  });
  renderedContent[key] = { ...renderedContent[key], ...singletonProperties };

  // START: lowestRanks for mobile and desktop
  renderedContent[key].lowestRanks = renderedContent[key].lowestRanks || {};
  const desktopIds = renderedContent[key].featureIds.filter(
    (id) => !renderedContent[key][id].hideOnDesktop
  );
  if (desktopIds.length) {
    renderedContent[key].lowestRanks.desktop = desktopIds.reduce((acc, id) => {
      const rank = renderedContent[key][id].curationRank;
      return !acc || rank < acc ? rank : acc;
    }, renderedContent?.[key]?.lowestRanks?.desktop);
  }
  const mobileIds = renderedContent[key].featureIds.filter(
    (id) => !renderedContent[key][id].hideOnMobile
  );
  if (mobileIds.length) {
    renderedContent[key].lowestRanks.mobile = mobileIds.reduce((acc, id) => {
      const rank = renderedContent[key][id].curationRank;
      return !acc || rank < acc ? rank : acc;
    }, renderedContent?.[key]?.lowestRanks?.mobile);
  }
  // END: lowestRanks for mobile and desktop
};

export const updateRenderedContentItems = ({
  renderedContent,
  items = [],
  id,
  chainOverrides,
  overrides,
  curationIndices
}) => {
  if (!reconcileDiscountDeduplicates(overrides, chainOverrides)) {
    const { hideFromDesktop, hideFromMobile, displayName } = chainOverrides;
    items.forEach((content) => {
      const hideOnDesktop = !!overrides?.hideOnDesktop || !!hideFromDesktop;
      const hideOnMobile =
        isFeatureHiddenOnMobile(overrides) || !!hideFromMobile;
      const key = getRenderedContentId({ content, overrides });
      const canonical_url = removeWWW(content?.canonical_url);
      updateRenderedContentKey({
        renderedContent,
        key,
        listProperties: { featureIds: id, chainDisplayNames: displayName },
        singletonProperties: {
          canonical_url,
          [id]: {
            hideOnDesktop,
            hideOnMobile,
            curationRank: curationIndices?.rank
          }
        }
      });
    });
  }
};

// START: for json app
// NOTE: jsonapp doesn't support contexts, but there is a cache object. So,
// for jsoapp, using cache in lieu of a context.
/*
 * @param {object} cache - The cache object that the jsoanpp uses
 * @param {string} key - The key in cache to use. For now, this exists
 *   in anticipation of needing separate keys for desktop ordering
 *   and mobile ordering
 *
 * @returns {object} - The content at the key
 */
export const getRenderedContentCache = (cache, key = "renderedContent") => {
  const cacheData = cache.extract();
  const initData = {};
  const existingData = get(cacheData, key, initData);
  if (existingData === initData) set(cacheData, key, initData);
  return existingData;
};

/*
 * @param {object} cache - The cache object that the jsoanpp uses
 * @param {string} key - The key in cache to use. For now, this exists
 *   in anticipation of needing separate keys for desktop ordering
 *   and mobile ordering
 *
 * @returns {object} - The content at the key
 */
export const cacheRenderedContentItem = ({
  props,
  item,
  curationIndices,
  customFields,
  parent,
  renderedContent
}) => {
  // NOTE: Data needed to populate renderedContent
  const chainCustomFields = parent?.props?.customFields || {};
  const { id } = props;
  const items = [item];
  updateRenderedContentItems({
    renderedContent,
    items,
    id,
    chainOverrides: chainCustomFields,
    overrides: customFields,
    curationIndices
  });
};
// END: for json app
