import React from "react";
import { useAppContext } from "fusion-context";

import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, Icon } from "@washingtonpost/wpds-ui-kit";
import Close from "@washingtonpost/wpds-assets/asset/close";

import useLiveEventContent from "~/shared-components/useLiveEvent";
import { useMultiView } from "~/shared-components/MultiViewContext";
import { LiveAlertPropTypes } from "../../../../proptypes/live-alert";

const LiveUpdateHpAlert = () => {
  const [breakingNewsContent, ackContent] =
    useLiveEventContent("breaking-news-bar");

  if (!breakingNewsContent || !breakingNewsContent.content) {
    return null;
  }

  return <HpAlert customFields={breakingNewsContent} dismiss={ackContent} />;
};

const StyledAlertContainer = styled("div", {
  // mobile top is set in css prop since we need to get values from context
  position: "sticky; position: -webkit-sticky",
  width: "100%",
  zIndex: "6",
  "@notSm": {
    top: "60px"
  },
  variants: {
    isAdmin: {
      true: {
        position: "unset"
      }
    }
  }
});

const getValueWithNoUnit = (valueWithUnit) =>
  /^(\d+)(px)?$/.test(valueWithUnit) ? Number(RegExp.$1) : 0;

export const HpAlert = ({ customFields, dismiss }) => {
  const { url, content, prefix } = customFields;
  const { isMultiView, height, top } = useMultiView();
  const { isAdmin } = useAppContext();

  const multiviewTopPosition = getValueWithNoUnit(top);
  const multiviewHeight = isMultiView ? getValueWithNoUnit(height) : 0;

  // eslint-disable-next-line react/prop-types
  const TextWrapper = ({ children }) => {
    return (
      <span className="alert-text" data-qa="alert-text">
        {children}
      </span>
    );
  };

  const ContentWrapper = url
    ? ({ children }) => (
        <a className="hover-inherit alert-url white" href={url}>
          <TextWrapper>{children}</TextWrapper>
        </a>
      )
    : TextWrapper;

  return (
    <React.Fragment>
      {content && (
        <StyledAlertContainer
          className={getClasses("hide-for-print flex bg-red")}
          data-link-group="alert"
          css={{ top: multiviewTopPosition + multiviewHeight }}
          isAdmin={isAdmin}
        >
          <div className="flex ml-auto mt-sm mb-sm mr-auto banner-max-width banner-ml w-100">
            <div className="flex w-100 banner-content-wrapper">
              <ContentWrapper>
                <strong className="bold font-xs">{prefix}</strong>
                {content}
              </ContentWrapper>
            </div>
          </div>
          <button onClick={dismiss} className="ma-sm pointer self-baseline">
            <Icon
              label="close"
              className="fill-current white"
              style={{ opacity: "80%" }}
            >
              <Close />
            </Icon>
          </button>
        </StyledAlertContainer>
      )}
    </React.Fragment>
  );
};

HpAlert.propTypes = LiveAlertPropTypes;
export default LiveUpdateHpAlert;
